import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import Layout from "../components/layout";
import ReadingTime from '../components/estimated-read-time';
import ShareButtons from "../components/share-buttons";
import { centeredWindowListItem } from "../styles";
import { Cutout, Panel, WindowHeader, Window} from "react95";

deckDeckGoHighlightElement();

export default function BlogPostTemplate({
  data
}) {
  const { markdownRemark } = data;
  const { excerpt, frontmatter, html, timeToRead, wordCount } = markdownRemark;
  const { title, date, categories = ['game development'], heroImg, heroAlt } = frontmatter;
  const heroImageData = heroImg ? getImage(heroImg) : null;
  const { words } = wordCount;
  return (
    <Layout
      description={excerpt}
      subtitle={title}
      type="article"
      articleData={
        {
          publishedDate: date,
          tags: categories,
        }
      }
    >
      <Window className="hero-container" style={centeredWindowListItem}>
        <WindowHeader className="hero-container-header" style={{ height: 'initial' }}>{title}</WindowHeader>
        <Cutout className="hero-cutout">
        {heroImg && heroAlt && heroImageData && (
          <GatsbyImage
            alt={heroAlt}
            image={heroImageData}
            className="hero-image"
            objectFit="fill"
            style={{
              width: '100%',
              borderBottomRightRadius: '3px',
              borderTopRightRadius: '3px',
            }}
          ></GatsbyImage>
        )}
        </Cutout>
        <Panel
            variant='well'
            style={{ margin: '1rem', padding: '0.1rem 0.25rem', width: '95%' }}
          >
          <span className="blog-post-date">{date || 'Unpublished Draft'}<br />
            <ReadingTime className="blog-post-date" wordcount={words} minutes={timeToRead} />
          </span>
        </Panel>
      </Window>
      <Panel as="article" className="blog-post" style={centeredWindowListItem}>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Panel>
      <ShareButtons
        title={`Read "${title}" at `}
        tags={categories}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      excerpt(pruneLength: 240)
      html
      frontmatter {
        categories
        date(formatString: "MMMM DD, YYYY")
        heroImg {
          childImageSharp {
            gatsbyImageData(
              width: 910
              placeholder: BLURRED
            )
          }
        }
        heroAlt
        slug
        title
      }
      timeToRead
      wordCount {
        words
      }
    }
  }
`;
