import React from 'react';

const ReadingTime = ({ minutes, wordcount }) => {
  if (!minutes || !wordcount) return null;
  return (
    <>
      {wordcount > 1 ? <p>{wordcount} words</p> : null}
      <p className="reading-time">Estimated reading time: {minutes} {minutes !== 1 ? 'minutes' : 'minute'}</p>
    </>
  )
}

export default ReadingTime;